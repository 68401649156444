import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";

export type UserPlaySpinReward = {
  created_at: string;
  description: string;
  id: number;
  link_to: string;
  link_to_table: string;
  link_to_table_id: string;
  name: string;
  nuke_chip: number;
  nuke_credit: number;
  nuke_damage: number;
  type: string;
  usd: number;
  user_id: string;
  try_double_reward: number;
  claimed_at: Date;
  nickname?: string;
  tx_distributed_usd_reward?: string;
};

export type UserPlaySpinRewardSummary = {
  avatar_url: string;
  nickname: string;
  total_nuke_credit: number;
  total_usd: number;
  user_id: number;
};

export type UserPlaySpinRewardTransaction = {
  avatar_url: string;
  count: number;
  created_at: string;
  nickname: string;
  nuke_chip: number;
  nuke_credit: number;
  nuke_damage: number;
  usd: number;
  type: string;
  user_id: string;
  change_nuke_chip: number;
};

export type UserPlaySpinRewardGroupData = {
  [link_to_table_id: string]: {
    total_credit: number;
    total_usd: number;
    created_at: Date;
    creditRewards: UserPlaySpinReward[];
    usdRewards: UserPlaySpinReward[];
  };
};

export type UserPlaySpinRewardAnalytics = {
  total_claimed_nuke_credit: number;
  total_claimed_usd: number;
  total_projected_nuke_credit: number;
  total_projected_usd: number;
  total_claimable_nuke_credit: number;
  total_claimable_usd: number;
};

export type UserSpinRewardData = {
  userSpinRewards: UserPlaySpinReward[];
  userSpinRewardsV2: UserPlaySpinReward[];
  userSpinRewardsGroup: UserPlaySpinRewardGroupData;
  userSpinRewardAnalytics: UserPlaySpinRewardAnalytics;
};

export class SpinSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    protected readonly publicInstance: SupabaseClient,
    private readonly userId: string,
    private readonly token: string,
  ) {
    super(instance);
  }

  public async playSpin(nSpin: number): Promise<UserPlaySpinReward[]> {
    try {
      const response = await fetch(this.gen("/games/spin"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({ nSpin }),
      });

      const data = (await response.json()) as UserPlaySpinReward[];
      const result = data.reduce<UserPlaySpinReward[]>((acc, item) => {
        if (item.usd) {
          acc.push({ ...item, nuke_credit: 0, usd: item.usd });
        }

        if (item.nuke_credit) {
          acc.push({ ...item, nuke_credit: item.nuke_credit, usd: 0 });
        }
        return acc;
      }, [] as UserPlaySpinReward[]);

      return result;
    } catch (error) {
      console.warn("Error when playing spin", error);
      return null;
    }
  }

  public async getSpinLeaderboard(
    offset_param = 0,
    limit_param = 10,
  ): Promise<UserPlaySpinRewardSummary[]> {
    let { data, error } = await this.publicInstance.rpc(
      "get_user_spin_rewards_summary",
      {
        limit_param,
        offset_param,
      },
    );

    if (error) {
      console.warn("Failed to get spin leaderboard", error);
      return [];
    }

    return data;
  }

  public async fetchSpinTransaction(
    offset = 0,
    limit = 10,
  ): Promise<UserPlaySpinRewardTransaction[]> {
    const { data, error } = await this.publicInstance
      .from("view_user_reward_aggregated")
      .select("*")
      .eq("type", "play_spin_reward")
      .order("created_at", { ascending: false })
      .range(offset ? offset * limit - 1 : 0, offset * limit + limit + 1);

    if (error) {
      console.warn("Failed to fetch leaderboard data", error);
      return [];
    }

    return data;
  }

  public async listenNewSpinTransaction(
    onNewTransaction: (transaction: UserPlaySpinReward) => void,
  ) {
    this.publicInstance
      .channel("user_reward")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "user_reward",
        },
        async (_payload: any) => {
          const payload = _payload?.new as UserPlaySpinReward;
          onNewTransaction(payload);
        },
      )
      .subscribe();
  }

  public async fetchUserSpinReward(): Promise<UserSpinRewardData> {
    const { data, error } = await this.publicInstance
      .from("user_reward")
      .select("*")
      .eq("type", "play_spin_reward")
      .eq("user_id", this.userId)
      .order("created_at", { ascending: false });

    if (error) {
      console.warn("Failed to fetch user spin reward", error);
      return {} as any;
    }

    const analytics = {
      total_claimed_nuke_credit: 0,
      total_claimed_usd: 0,
      total_projected_nuke_credit: 0,
      total_projected_usd: 0,
      total_claimable_nuke_credit: 0,
      total_claimable_usd: 0,
    };

    const userSpinRewardsGroup = (
      data as UserPlaySpinReward[]
    ).reduce<UserPlaySpinRewardGroupData>((acc, item) => {
      if (!acc[item.link_to_table_id]) {
        acc[item.link_to_table_id] = {
          created_at: new Date(item.created_at),
          total_credit: 0,
          total_usd: 0,
          creditRewards: [],
          usdRewards: [],
        };
      }

      if (item.nuke_credit > 0) {
        acc[item.link_to_table_id].total_credit += item.nuke_credit;
        acc[item.link_to_table_id].creditRewards.push(item);
      }

      if (item.usd > 0) {
        acc[item.link_to_table_id].total_usd += item.usd;
        acc[item.link_to_table_id].usdRewards.push(item);
      }

      if (!item.claimed_at) {
        analytics.total_claimable_nuke_credit += item.nuke_credit;
        analytics.total_claimable_usd += item.usd;
      } else {
        analytics.total_claimed_nuke_credit += item.nuke_credit;
        analytics.total_claimed_usd += item.usd;
      }

      return acc;
    }, {});

    // return [];
    const userSpinRewards = Object.values(userSpinRewardsGroup).reduce<
      UserPlaySpinReward[]
    >((acc, item) => {
      // split credit and usd
      if (item.total_credit > 0) {
        acc.push({
          usd: 0,
          created_at: item.created_at,
          nuke_credit: item.total_credit,
        } as any);
      }

      if (item.total_usd > 0) {
        acc.push({
          nuke_credit: 0,
          created_at: item.created_at,
          usd: item.total_usd,
        } as any);
      }

      return acc;
    }, [] as UserPlaySpinReward[]);

    const userSpinRewardsV2 = (data as UserPlaySpinReward[])
      // .filter((t) => !t.claimed_at)
      .sort((a, b) => {
        return (
          Number(!!a.claimed_at) - Number(!!b.claimed_at) ||
          new Date(b.claimed_at).getTime() - new Date(a.claimed_at).getTime() ||
          Number(b.nuke_credit > 0) - Number(a.nuke_credit > 0) ||
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime() ||
          b.nuke_credit - a.nuke_credit ||
          b.usd - a.usd
        );
      });

    return {
      userSpinRewardsV2,
      userSpinRewards,
      userSpinRewardsGroup,
      userSpinRewardAnalytics: analytics,
    };
  }
}
