import React, { ReactNode, useCallback, useContext } from "react";
import { TokenInfo } from "@solana/spl-token-registry";
import _ from "lodash";

const TokenContext = React.createContext<{
  handleFetchTokenList: (mints: string[]) => Promise<void>;
} | null>(null);

export function TokenContextProvider({ children }: { children: ReactNode }) {
  const authHeader =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhtdGpnc250dGdkdXFkaWJzeW9iIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMxNjI1NDcsImV4cCI6MjA0ODczODU0N30.h_0PjUImhVykc5n73lmQqmdjMDbR4aFTBaW2JA78tD4";

  const handleFetchTokenList = useCallback(async (mints: string[]) => {
    const rateLimitPerMinute = 30;

    const tokenPortfolioList = JSON.parse(
      localStorage.getItem("jupiter-terminal-token-portfolio-list"),
    ) as any;

    const toBeFetched = _.uniq(
      mints.filter((mint) => {
        return !(tokenPortfolioList?.data || [])?.find(
          (token: any) => token.address === mint,
        );
      }),
    );

    // now we split the token list into chunks of 30 per minutes
    const chunks = _.chunk(_.uniq(toBeFetched), rateLimitPerMinute);
    let results: TokenInfo[] = [];

    if (toBeFetched.length > 0) {
      const buildUrl = async (mint: string) =>
        (
          await fetch(
            `https://hmtjgsnttgduqdibsyob.supabase.co/functions/v1/terminal/api/jupiter/token/${mint}`,
            {
              headers: {
                Authorization: authHeader,
              },
            },
          )
        ).json();

      for (const chunk of chunks) {
        const promises = chunk.map((mint) => buildUrl(mint));
        const responses = await Promise.all(promises);
        results.push(...responses);
      }

      // cache the result
      localStorage.setItem(
        "jupiter-terminal-token-portfolio-list",
        JSON.stringify({
          timestamp: Date.now(),
          data: tokenPortfolioList?.data?.concat(results) || [],
        }),
      );
    }
  }, []);

  return (
    <TokenContext.Provider
      value={{
        handleFetchTokenList,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
}

export function useTokenContext() {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("TokenContext not found");
  }
  return context;
}
