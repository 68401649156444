import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";

export interface DumpTransaction {
  network: string;
  tx: string;
  signer: string;
  from_token_address: string;
  to_token_address: string;
  from_amount_raw: number;
  to_amount_raw: number;
  from_amount: number;
  to_amount: number;
  from_usd_value: number;
  to_usd_value: number;
  user_id: string;
  fee_token_address: string;
  fee_amount_raw: number;
  fee_amount: number;
  fee_usd_value: number;
  transaction_executed_at: number; // Assuming this is a timestamp in milliseconds.
  total_volume_usd: number;
  process_status: number;
  fee_to_address: string;
  tx_type: string;
  id: string;
  nickname: string;
  avatar_url: string;
}

export const exampleDumpTransaction: DumpTransaction = {
  network: "Ethereum",
  tx: "0xabc12345def67890ghijklmno1234567890",
  signer: "0x123abc456def7890abcdef1234567890abcdef",
  from_token_address: "0xabcdef1234567890abcdef1234567890abcdef",
  to_token_address: "0x1234567890abcdef1234567890abcdef123456",
  from_amount_raw: 1000000000000000000,
  to_amount_raw: 500000000000000000,
  from_amount: 1.0,
  to_amount: 0.5,
  from_usd_value: 1200,
  to_usd_value: 600,
  user_id: "user1234",
  fee_token_address: "0x0000000000000000000000000000000000000000",
  fee_amount_raw: 20000000000000000,
  fee_amount: 0.02,
  fee_usd_value: 24,
  transaction_executed_at: 1691234567890,
  total_volume_usd: 1800,
  process_status: 1,
  fee_to_address: "0x0000000000000000000000000000000000000001",
  tx_type: "swap",
  id: "tx12345",
  nickname: "JohnDoe",
  avatar_url: "https://www.example.com/avatar.jpg",
} as DumpTransaction;

export interface LeaderboardEntry {
  rank: number;
  user_id: string;
  contribution: string;
  purges: number;
  nickname: string;
  avatar_url: string;
  nuke_credit: number;
  nuke_damage: number;
}

export interface LeaderboardResponse {
  address: string;
  avatar_url: string;
  count_dump_transaction: number;
  network: string;
  nickname: string;
  rank: number;
  ref_from_user_id: string;
  refcode: string;
  total_volume_usd: number;
  user_id: string;
  nuke_credit: number;
  nuke_damage: number;
  contribution: number;
}

function mapTransactionData(response: any) {
  return {
    transaction_id: response.transaction_id,
    network: response.transaction_data.f1,
    tx: response.transaction_data.f2,
    signer: response.transaction_data.f3,
    from_token_address: response.transaction_data.f4,
    to_token_address: response.transaction_data.f5,
    from_amount_raw: response.transaction_data.f6,
    to_amount_raw: response.transaction_data.f7,
    from_amount: response.transaction_data.f8,
    to_amount: response.transaction_data.f9,
    from_usd_value: response.transaction_data.f10,
    to_usd_value: response.transaction_data.f11,
    user_id: response.transaction_data.f12,
    fee_token_address: response.transaction_data.f13,
    fee_amount_raw: response.transaction_data.f14,
    fee_amount: response.transaction_data.f15,
    fee_usd_value: response.transaction_data.f16,
    transaction_executed_at: response.transaction_data.f17,
    total_volume_usd: response.transaction_data.f18,
    process_status: response.transaction_data.f19,
    fee_to_address: response.transaction_data.f20,
    tx_type: response.transaction_data.f21,
    id: response.transaction_data.f22,
    nickname: response.nickname,
    avatar_url: response.avatar_url,
  };
}

export class PurgeSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    private readonly publicInstance: SupabaseClient,
    private readonly userId: string,
  ) {
    super(instance);
  }

  public async storeTxHash(txHash: string): Promise<void> {
    await this.instance
      .from("tx_hashes")
      .insert([{ user_id: this.userId, tx_hash: txHash }]);
  }

  public async listenNewDumpTransactions(
    onNewTransaction: (transaction: DumpTransaction) => void,
  ) {
    this.publicInstance
      .channel("dump_transaction")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "dump_transaction" },
        async (_payload: any) => {
          const payload = _payload?.new as DumpTransaction;
          const { data: userData, error: userError } = await this.publicInstance
            .from("user_profile")
            .select("*")
            .eq("user_id", payload.user_id);

          if (userData && userData.length) {
            payload.nickname = userData[0].nickname;
            payload.avatar_url = userData[0].avatar_url;
          }

          onNewTransaction(payload);
        },
      )
      .subscribe();
  }

  public async fetchTokenStats(address: string): Promise<{
    token_address: string;
    damages: number;
    nukers: number;
  }> {
    const { data, error } = await this.publicInstance.rpc("fetch_token_stats", {
      address_param: address,
    });

    if (error) {
      console.warn("Failed to fetch dump transactions", error);
      return null;
    }

    return data[0];
  }

  public async fetchUserDumpTransactions(
    offset_val = 0,
    limit_val = 10,
    tx_type: string,
  ): Promise<DumpTransaction[]> {
    const { data, error } = await this.publicInstance.rpc(
      "fetch_user_dump_transaction",
      {
        limit_param: limit_val,
        offset_param: offset_val,
        tx_type_param: tx_type,
      },
    );

    if (error) {
      console.warn("Failed to fetch dump transactions", error);
      return [];
    }

    return data.map(mapTransactionData);
  }

  public async fetchAllDumpTransactions(
    offset_val = 0,
    limit_val = 10,
  ): Promise<DumpTransaction[]> {
    const { data, error } = await this.publicInstance.rpc(
      "fetch_dump_transactions_with_user",
      {
        limit_param: limit_val,
        offset_param: offset_val,
      },
    );

    if (error) {
      console.warn("Failed to fetch dump transactions", error);
      return [];
    }

    return data.map(mapTransactionData);
  }

  public async getTotalNukeDamage(userId: string): Promise<{
    total_damage: number;
    contribution: number;
    total_purges: number;
  } | null> {
    const { data, error } = await this.publicInstance.rpc("get_nuke_stats", {
      user_uuid: userId,
    });

    if (error) {
      console.warn("Failed to fetch total nuke damage", error);
      return null;
    }

    return data[0] as {
      total_damage: number;
      contribution: number;
      total_purges: number;
    } | null;
  }

  public async fetchLeaderboardData(
    offset = 0,
    limit = 10,
  ): Promise<LeaderboardResponse[]> {
    const { data: data, error: error } = await this.publicInstance
      .from("view_user_dump_rank")
      .select("*")
      .order("rank", { ascending: true })
      .range(offset ? offset * limit - 1 : 0, offset * limit + limit + 1);

    if (error) {
      console.warn("Failed to fetch leaderboard data", error);
      return [];
    }

    return data;
  }
}
