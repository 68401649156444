import {
  NavGroupItem,
  NavItem,
  NavItemInternal,
} from "@/types/navigation-types";
import { useRouter } from "next/router";
import { useMemo } from "react";

export function isGroupItem(
  item: NavItem | NavGroupItem
): item is NavGroupItem {
  return "subItems" in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return "nextRoute" in item;
}

export const useNav = () => {
  const router = useRouter();

  return useMemo(() => {
    const navItems = (
      [
        {
          text: "purrge",
          nextRoute: { pathname: "/" },
          subUrls: ["/coin/[input]"],
        },
        {
          text: "claim SOL",
          nextRoute: { pathname: "/claim-sol" },
        },
        {
          text: "spin",
          nextRoute: { pathname: "/spin" },
          disabled: false,
        },
        {
          text: "refurral",
          nextRoute: { pathname: "/referral" },
          disabled: false,
        },
        {
          text: "hypurrflow",
          nextRoute: { pathname: "/hypurrflow" },
          disabled: true,
        },
        {
          text: "purr-v-purr",
          nextRoute: { pathname: "/purr-v-purr" },
          disabled: true,
        },
      ] as NavItem[]
    ).map(({ ...data }) => ({
      ...data,
    }));

    return {
      navItems: navItems.map((item) => ({
        ...item,
        isActive:
          router.pathname === (item as any).nextRoute.pathname ||
          (item as any).subUrls?.includes(router.pathname),
      })),
    };
  }, [router]);
};
