import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";

export type IComment = {
  id: string;
  created_at: Date;
  topic: string;
  user_id: string;
  message: string;
  image_url: string;
  reply_to_chat_id: string;
  count_reply: string;
  count_like: number;
};

export type IMyReaction = {
  chat_id: string;
  user_id: string;
  reaction: number;
};

export type ICommentV2 = {
  id: string;
  created_at: Date;
  topic: string;
  user_id: string;
  message: string;
  image_url: string;
  reply_to_chat_id: string;
  like: { count: number }[];
  dislike: { count: number }[];
  my_reaction: IMyReaction[];
  user_profile: { user_id: string; nickname: string; avatar_url: string };
};

export type ReactionComment = {
  created_at: Date;
  chat_id: string;
  reaction: number;
  user_id: string;
};

export type CommentDtoPayload = {
  message: string;
  image_url?: string;
};

export class ChatSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    private readonly userId: string
  ) {
    super(instance);
  }

  async getComments(
    topic: string,
    limit = 10,
    offset = 0
  ): Promise<IComment[]> {
    const { data, error } = await this.instance
      .from("comment")
      .select("*")
      .eq("topic", topic)
      .order("created_at", { ascending: false })
      .range(offset, offset + limit - 1);

    if (error) {
      console.error("Error fetching comments", error);
      return [];
    }

    return data ?? [];
  }

  async getCommentsV2(
    topic: string,
    limit = 10,
    offset = 0,
    id: string|undefined = undefined
  ): Promise<ICommentV2[]> {
    const aggregator = this.instance
      .from("comment")
      .select(
        `
            *,
            like:reaction_comment (
                count
            ),
            dislike:reaction_comment (
                count
            ),
            user_profile (
                user_id,
                nickname,
                avatar_url
            ),
            my_reaction:reaction_comment (
                chat_id,
                user_id,
                reaction
            )
        `
      )
      .eq("topic", topic)
      .eq("like.reaction", 1)
      .eq("dislike.reaction", 2);

    if(id) {
        aggregator.eq("id", id);
    }
    if (this.userId) {
      aggregator.eq("my_reaction.user_id", this.userId);
    }

    aggregator
      .order("created_at", { ascending: false })
      .range(offset, offset + limit - 1);

    const { data, error } = await aggregator;
    if (error) {
      console.error("Error fetching comments", error);
      return [];
    }

    return data ?? [];
  }

  async postComment(topic: string, payload: CommentDtoPayload) {
    const { error } = await this.instance
      .from("comment")
      .insert([{ ...payload, topic, user_id: this.userId }]);

    if (error) {
      console.warn("Error posting comment", error);
      throw new Error("Error posting comment", error);
    }

    return payload;
  }

  async reactComment(
    chat_id: string,
    reaction: number
  ): Promise<{
    chat_id: string;
    reaction: number;
  }> {
    const existing = await this.instance
      .from("reaction_comment")
      .select("*")
      .eq("chat_id", chat_id)
      .eq("user_id", this.userId);

    if (existing.data.length) {
      const _reaction = existing.data[0].reaction;
      if (_reaction === reaction) {
        const deleted = await this.instance
          .from("reaction_comment")
          .delete()
          .eq("chat_id", chat_id)
          .eq("user_id", this.userId);

        if (deleted.error) {
          console.log("Error deleting reaction", deleted.error);
          throw new Error("Error deleting reaction", deleted.error);
        }

        return;
      } else {
        const updated = await this.instance
          .from("reaction_comment")
          .update({ reaction })
          .eq("chat_id", chat_id)
          .eq("user_id", this.userId);

        if (updated.error) {
          console.warn("Error updating reaction", updated.error);
          throw new Error("Error updating reaction", updated.error);
        }

        return;
      }
    }

    const { error } = await this.instance
      .from("reaction_comment")
      .insert([{ chat_id, reaction, user_id: this.userId }]);

    if (error) {
      console.warn("Error reacting to comment", error);
      throw new Error("Error reacting to comment", error);
    }

    return { chat_id, reaction };
  }

  async getReactions(
    chat_id: string
  ): Promise<{ reactions: ReactionComment[] }> {
    const { data, error } = await this.instance
      .from("reaction_comment")
      .select("*")
      .eq("chat_id", chat_id);

    if (error) {
      throw new Error("Error fetching reactions", error);
    }

    return {
      reactions: data ?? [],
    } as any;
  }
}
