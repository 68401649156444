import {
  Box,
  Flex,
  Text,
  TextProps,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";

import type { NavItem } from "@/types/navigation-types";
import { useRouter } from "next/router";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "./PopoverModal";

type Props = {
  item: NavItem;
  color?: string;
  px?: string | number;
  isActive?: boolean;
  onClick?: () => void;
} & Partial<TextProps>;

export const NavLink = ({
  item,
  color,
  isActive,
  onClick,
  ...props
}: Props) => {
  const router = useRouter();
  const content = (
    <Text
      borderRadius={{ base: "md", lg: "unset" }}
      whiteSpace="nowrap"
      onClick={() => {
        if (item.disabled) return;
        if ((item as any)?.nextRoute) {
          router.push((item as any).nextRoute?.pathname);
        }

        onClick && onClick();
      }}
      width="full"
      height="40px"
      role="group"
      color={color || "secondary.gray"}
      _hover={{
        color: "neutral.2",
      }}
      fontWeight={400}
      fontSize="20px"
      gap={2}
      borderBottom={{ base: "none", lg: isActive ? "1px solid" : "none" }}
      borderColor="primary.red"
      {...props}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      cursor={
        !item.disabled
          ? "url('/icons/cursor-pointer.svg'), pointer"
          : "not-allowed"
      }
    >
      {item.text}
    </Text>
  );

  return content;
};

export const NavLinkDropDown: FC<{ items: NavItem[] }> = ({ items }) => {
  const modalDisclosure = useDisclosure();

  return (
    <Box position="relative">
      <PopoverModal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        order={3}
        position={{ base: "unset" }}
        w={{ base: "unset", xl: "unset", lg: "unset" }}
        top={{ base: "unset", lg: "unset" }}
        bottom={{ base: "unset", lg: "unset" }}
        boxShadow="unset"
      >
        <PopoverModalTrigger>
          <Flex
            gap="4px"
            py="8px"
            height="40px"
            alignItems="center"
            cursor="url('/icons/cursor-pointer.svg'), pointer"
            onClick={() => modalDisclosure.onToggle()}
            role="group"
          >
            <Text
              fontSize="20px"
              px="16px"
              color={
                modalDisclosure.isOpen ? "primary.red.hover" : "primary.red"
              }
              borderBottom="1px solid"
              borderColor={
                modalDisclosure.isOpen ? "primary.red.hover" : "primary.red"
              }
            >
              {items.find((item) => item.isActive)?.text}
            </Text>
            <Image
              src={
                modalDisclosure.isOpen
                  ? "/icons/dropdown-hover.svg"
                  : "/icons/dropdown.svg"
              }
              w="20px"
              h="20px"
              transition="transform 0.3s linear"
              transform={`rotate(${modalDisclosure.isOpen ? 180 : 0}deg)`}
              _groupHover={{
                content: 'url("/icons/dropdown-hover.svg")',
              }}
            />
          </Flex>
        </PopoverModalTrigger>
        <PopoverModalContent
          px="16px"
          left="0px"
          top="40px"
          width="130px"
          overflow="hidden"
          display="flex"
          flexDir="column"
        >
          {items
            .filter((item) => !item.isActive)
            .map((item) => (
              <NavLink
                key={`${Math.random()}-${item.text}`}
                item={item}
                color={
                  item.disabled
                    ? "secondary.liteGray"
                    : (item as any)?.isActive
                      ? "primary.red"
                      : "secondary.gray"
                }
                _hover={{
                  color: !item.disabled
                    ? "primary.red.hover"
                    : "secondary.liteGray",
                }}
                cursor={
                  !item.disabled
                    ? "url('/icons/cursor-pointer.svg'), pointer"
                    : "not-allowed"
                }
                isActive={(item as any)?.isActive}
                borderBottom="none"
                onClick={() => {
                  modalDisclosure.onClose();
                }}
              />
            ))}
        </PopoverModalContent>
      </PopoverModal>
    </Box>
  );
};
