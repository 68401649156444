import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";

export interface UserRefCommission {
  id: string;
  created_at: string;
  user_id: string;
  total_volume_usd: number;
  total_commission: number;
  received_commission: number;
  reward_nuke_credit: number;
  commission_percent: number;
  count_active_user: number;
}

export interface UserReward {
  claimed_at: Date;
  created_at: string;
  description: string;
  id: number;
  link_to: string;
  link_to_table: string;
  link_to_table_id: string;
  name: string;
  nuke_chip: number;
  nuke_credit: number;
  nuke_damage: number;
  try_double_reward: number;
  type: string;
  usd: number;
  user_id: string;
}

export class ReferralSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    private readonly publicInstance: SupabaseClient,
    private readonly userId: string
  ) {
    super(instance);
  }

  public async getUserRefCommission(): Promise<UserRefCommission> {
    const { data, error } = await this.instance
      .from("user_ref_commission")
      .select("*")
      .eq("user_id", this.userId);

    if (!data || !data.length || error) {
      console.warn("Failed to get user ref comm", error);
      return null;
    }

    return data[0];
  }

  public async fetchUserReward(): Promise<UserReward[]> {
    const { data, error } = await this.instance
      .from("user_reward")
      .select("*")
      .eq("user_id", this.userId)
      .eq("type", "ref_reward_nuke_credit");

    if (error) {
      console.warn("Failed to fetch user referral rewards", error);
      return [];
    }

    return data;
  }

  public async claimReferralReward(
    rewardId: number,
    bet_double_reward = false
  ): Promise<any> {
    const { data, error } = await this.instance.functions.invoke(
      "claim-reward",
      {
        body: {
          rewardId: rewardId,
          bet_double_reward,
        },
      }
    );

    if (error) {
      console.warn("Failed to claim referral reward", error);
      throw new Error("Failed to claim referral reward");
    }

    return data;
  }
}
