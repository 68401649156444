import {
  SUPABASE_URL,
  REF_CODE_STORE_KEY,
  ACCESS_TOKEN_STORE_KEY,
  REFRESH_TOKEN_STORE_KEY,
  PUBLIC_SUPABASE_ANON_KEY,
  ACCESS_TOKEN_USER_ID,
} from "@/lib/constants/supabase";
import { StorageProvider } from "@/lib/providers/storage.provider";
import { UtilsProvider } from "@/lib/providers/utils.provider";
import {
  ChatSupabaseService,
  CommentDtoPayload,
} from "@/lib/services/chat.supbase.service";
import {
  ProfileSupabaseService,
  User,
  UserProfile,
} from "@/lib/services/profile.supabase.service";
import {
  PurgeProfileSupabaseService,
  UserLevel,
  UserLevelInfo,
} from "@/lib/services/purge-profile.supabase.service";
import { PurgeSupabaseService } from "@/lib/services/purge.supabase.service";
import { ReferralSupabaseService } from "@/lib/services/referral.supabase.service";
import { SpinSupabaseService } from "@/lib/services/spin.supabase.service";
import { UtilitySupabaseService } from "@/lib/services/utility.supabase.service";
import { LevelWithCurrentValue } from "@/types/supabase.entity";
// import { useWallet } from "@solana/wallet-adapter-react";
import { useWallet } from "@jup-ag/wallet-adapter";
import { createClient } from "@supabase/supabase-js";
import * as bs58 from "bs58";
import {
  type FC,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";

let intervalFn: any;

type AuthResponse = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  user: {
    id: string;
    address: string | undefined;
  };
};

export const getInstance = (accessToken: string) => {
  return createClient(SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY, {
    global: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
};

type PublicService = {
  profile: ProfileSupabaseService;
  utility: UtilitySupabaseService;
  chat: ChatSupabaseService;
  purge: PurgeSupabaseService;
  spin: SpinSupabaseService;
};

type AuthService = {
  profile: ProfileSupabaseService;
  chat: ChatSupabaseService;
  purge: PurgeSupabaseService;
  purgeProfile: PurgeProfileSupabaseService;
  referral: ReferralSupabaseService;
  spin: SpinSupabaseService;
};

type UserData = {
  userLevel: UserLevel | undefined;
  userLevelInfos: UserLevelInfo[];
};

type ContextProps = {
  auth: AuthResponse;
  publicService: PublicService;
  authService: AuthService;

  userData: UserData;

  // Profile
  logout(): void;
  loginSolana(): Promise<void>;
  fetchProfile(): Promise<void>;

  // Chat
  postComment(topic: string, payload: CommentDtoPayload): Promise<void>;
} & {
  user: UserProfile;
  levelWithCurrentVolume: LevelWithCurrentValue[];
  myTotalScore: { nuke: number; damage: number; chip: number };
  refreshUserLevelInfo(): void;
  handleGetUserLevel(): void;
  resetUserData(): void;
  fetchUserData(): void;
};

const AuthContext = createContext<ContextProps | undefined>(undefined);

export const SupabaseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<AuthResponse>();
  const supabase = createClient(SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY);
  const { publicKey, signMessage } = useWallet();

  const [userId, setUserId] = useState<string>(
    new StorageProvider().getItem(ACCESS_TOKEN_USER_ID) || ""
  );
  const [accessToken, setAccessToken] = useState(
    new StorageProvider().getItem(ACCESS_TOKEN_STORE_KEY) || ""
  );

  const [publicService, setPublicService] = useState<PublicService>();
  const [authService, setAuthService] = useState<AuthService>();
  const [levelWithCurrentVolume, setLevelWithCurrentVolume] = useState<
    LevelWithCurrentValue[]
  >([]);

  const [userData, setUserData] = useState<UserData>({
    userLevel: null,
    userLevelInfos: [],
  });
  const [myTotalScore, setMyTotalScore] = useState({
    nuke: 0,
    damage: 0,
    chip: 0,
  });

  const userProfileForm = useForm<UserProfile>({
    defaultValues: {
      nickname: "",
      user_id: "",
      avatar_url: "",
      bio: "",
      level: 0,
      atk: 0,
      def: 0,
      eva: 0,
    },
  });

  const userProfile = useWatch({ control: userProfileForm.control });

  const refreshUserLevelInfo = useCallback(async () => {
    if (!authService || !authService.purgeProfile) {
      return;
    }

    const userBalance = await authService.purgeProfile.getUserBalance();
    if (userBalance) {
      setMyTotalScore((prev) => ({
        ...prev,
        nuke: userBalance.nuke_credit,
        damage: userBalance.nuke_damage,
        chip: userBalance.nuke_chip,
      }));
    }
  }, [authService]);

  const handleRefreshToken = useCallback(async () => {
    const refreshToken = new StorageProvider().getItem(REFRESH_TOKEN_STORE_KEY);
    return await supabase.auth.refreshSession({ refresh_token: refreshToken });
  }, []);

  const handleFetchAuthProfile = useCallback(async () => {
    const fnc = async (token: string, userId: string) => {
      if (!token) throw new Error("No token found");

      const profileService = new ProfileSupabaseService(
        getInstance(token),
        userId
      );

      const authProfile = await profileService.getProfile();
      if (authProfile.error) {
        throw new Error(authProfile.error.message);
      }

      const chatService = new ChatSupabaseService(getInstance(token), userId);
      const purgeService = new PurgeSupabaseService(
        getInstance(token),
        supabase,
        userId
      );
      const purgeProfileService = new PurgeProfileSupabaseService(
        getInstance(token),
        supabase,
        userId
      );
      const referralService = new ReferralSupabaseService(
        getInstance(token),
        supabase,
        userId
      );
      const spinService = new SpinSupabaseService(
        getInstance(token),
        supabase,
        userId,
        token
      );

      setAuthService({
        profile: profileService,
        chat: chatService,
        purge: purgeService,
        purgeProfile: purgeProfileService,
        referral: referralService,
        spin: spinService,
      });

      const r = await profileService.getUser(userId);
      const user = r.data?.[0] as User | undefined;

      setAuth({
        access_token: token,
        token_type: "Bearer",
        refresh_token: new StorageProvider().getItem(REFRESH_TOKEN_STORE_KEY),
        user: {
          id: userId,
          address: user?.address,
        },
      });

      userProfileForm.setValue("user_id", userId);
      return authProfile;
    };

    try {
      const distributedToken =
        accessToken || new StorageProvider().getItem(ACCESS_TOKEN_STORE_KEY);

      const _userId =
        new StorageProvider().getItem(ACCESS_TOKEN_USER_ID) || userId;

      return await fnc(distributedToken, _userId);
    } catch {
      const refreshTokenData = await handleRefreshToken();
      if (refreshTokenData.error) {
        return null;
      }

      new StorageProvider().setItem(
        REFRESH_TOKEN_STORE_KEY,
        refreshTokenData.data.session.access_token
      );

      new StorageProvider().setItem(
        REFRESH_TOKEN_STORE_KEY,
        refreshTokenData.data.session.refresh_token
      );

      try {
        return await fnc(
          refreshTokenData.data.session.access_token,
          refreshTokenData.data.user.id
        );
      } catch {
        return null;
      }
    }
  }, [accessToken, auth]);

  const handleFetchProfile = useCallback(async () => {
    if (!authService?.profile) return;
    const userProfile = await authService.profile.getUserProfile();
    for (const key in userProfile) {
      userProfileForm.setValue(key as any, (userProfile as any)[key]);
    }
  }, [authService, auth]);

  const loginSolana = useCallback(async () => {
    if (!publicKey) return;

    var nonce = Date.now();
    const message = Buffer.from("unpump.fun request_id: " + nonce, "utf-8");
    const signature = await signMessage(message as any);

    const response = (await new Promise((resolve, reject) => {
      fetch("/api/login-sol", {
        headers: {
          contentType: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          nonce,
          signature: bs58.default.encode(signature),
          address: publicKey.toBase58().toString(),
          refcode: new StorageProvider().getItem(REF_CODE_STORE_KEY) || "",
        }),
      })
        .then((res) => res.json())
        .then((data) => resolve(data))
        .catch((error) => {
          console.error("error", error);
          reject(error);
        });
    })) as {
      error: string;
      data: {
        data: AuthResponse;
      };
    };

    if (response.error) {
      throw new Error(response.error);
    }

    new StorageProvider().setItem(
      ACCESS_TOKEN_STORE_KEY,
      response.data.data.access_token
    );
    new StorageProvider().setItem(
      REFRESH_TOKEN_STORE_KEY,
      response.data.data.refresh_token
    );
    new StorageProvider().setItem(
      ACCESS_TOKEN_USER_ID,
      response.data.data.user.id
    );
    setAccessToken(response.data.data.access_token);
    setUserId(response.data.data.user.id);
    setAuth({
      ...response.data.data,
      user: {
        ...response.data.data.user,
        address: publicKey.toBase58().toString(),
      },
    });
    const chatService = new ChatSupabaseService(
      getInstance(response.data.data.access_token),
      response.data.data.user.id
    );
    const profileService = new ProfileSupabaseService(
      getInstance(response.data.data.access_token),
      response.data.data.user.id
    );

    setAuthService({
      profile: profileService,
      chat: chatService,
      purge: new PurgeSupabaseService(
        getInstance(response.data.data.access_token),
        supabase,
        response.data.data.user.id
      ),
      purgeProfile: new PurgeProfileSupabaseService(
        getInstance(response.data.data.access_token),
        supabase,
        response.data.data.user.id
      ),
      referral: new ReferralSupabaseService(
        getInstance(response.data.data.access_token),
        supabase,
        response.data.data.user.id
      ),
      spin: new SpinSupabaseService(
        getInstance(response.data.data.access_token),
        supabase,
        response.data.data.user.id,
        response.data.data.access_token
      ),
    });
    userProfileForm.setValue("user_id", userId);
  }, [publicKey, signMessage]);

  const logout = useCallback(() => {
    new StorageProvider().removeItem(REFRESH_TOKEN_STORE_KEY);
    new StorageProvider().removeItem(ACCESS_TOKEN_STORE_KEY);
    new StorageProvider().removeItem(ACCESS_TOKEN_USER_ID);
    setAccessToken("");
    setUserId("");
    setAuth(undefined);
    setAuthService(undefined);
    setMyTotalScore({ nuke: 0, damage: 0, chip: 0 });
    userProfileForm.reset();
  }, []);

  const postComment = useCallback(
    async (topic: string, payload: CommentDtoPayload) => {
      if (!authService?.chat) return;
      await authService.chat.postComment(topic, payload);
    },
    [authService]
  );

  const handleGetLevelsWithCurrentVolume = useCallback(async () => {
    const levels = await supabase.rpc("get_levels_info");
    if (!levels.error) {
      setLevelWithCurrentVolume(levels.data as LevelWithCurrentValue[]);
    }
  }, []);

  const handleGetUserLevel = useCallback(async () => {
    if (!authService?.purgeProfile) return;
    const userLevel = await authService.purgeProfile.getUserLevel();
    const userLevelInfos = await authService.purgeProfile.getUserLevelInfo(0);
    setUserData((prev) => ({ ...prev, userLevel, userLevelInfos }));
  }, [authService]);

  const resetUserData = useCallback(() => {
    setUserData({ userLevel: null, userLevelInfos: [] });
    setMyTotalScore({ nuke: 0, damage: 0, chip: 0 });
    setAuthService({
      chat: null,
      profile: null,
      purge: null,
      spin: null,
      purgeProfile: null,
      referral: null,
    });
  }, []);

  const fetchUserData = useCallback(() => {
    handleGetLevelsWithCurrentVolume();
    refreshUserLevelInfo();
    handleGetUserLevel();
    handleFetchProfile();
  }, [
    handleGetLevelsWithCurrentVolume,
    refreshUserLevelInfo,
    handleGetUserLevel,
    handleFetchProfile,
  ]);

  useEffect(() => {
    setPublicService({
      profile: new ProfileSupabaseService(supabase, ""),
      utility: new UtilitySupabaseService(supabase),
      chat: new ChatSupabaseService(supabase, ""),
      purge: new PurgeSupabaseService(supabase, supabase, ""),
      spin: new SpinSupabaseService(supabase, supabase, "", ""),
    });
  }, []);

  useEffect(() => {
    if (!publicKey) {
      resetUserData();
      return;
    }
    handleFetchAuthProfile().then(() => {
      fetchUserData();
    });
  }, [publicKey]);

  useEffect(() => {
    fetchUserData();
  }, [authService]);

  useEffect(() => {
    intervalFn && clearTimeout(intervalFn);
    intervalFn = new UtilsProvider().withInterval(() => {
      refreshUserLevelInfo();
      handleGetUserLevel();
    }, 1000 * 7);

    return () => {
      intervalFn && clearTimeout(intervalFn);
    };
  }, [publicKey, authService]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        postComment,
        loginSolana,
        refreshUserLevelInfo,
        handleGetUserLevel,
        fetchProfile: handleFetchProfile,
        myTotalScore,
        levelWithCurrentVolume,
        user: { ...userProfile },
        resetUserData,
        fetchUserData,
        userData,
        authService: authService || {
          chat: null,
          purge: null,
          profile: null,
          referral: null,
          purgeProfile: null,
          spin: null,
        },
        publicService: publicService || {
          profile: null,
          utility: null,
          chat: null,
          purge: null,
          spin: null,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useSupabase = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
};
