import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SOLANA_ENDPOINT } from "@/lib/configs";

type SolanaWeb3ProviderProps = { network: string; connection: Connection };

export const SolanaWeb3Context = createContext<SolanaWeb3ProviderProps>(null);

export const SolanaWeb3Provider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const network = clusterApiUrl(WalletAdapterNetwork.Mainnet);
  const connection = new Connection(
    "https://virulent-attentive-bush.solana-mainnet.quiknode.pro/e51acf8020b699cdb7e5f2ab798224378710e2ed",
    "confirmed",
  );
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new WalletConnectWalletAdapter({
        network: WalletAdapterNetwork.Mainnet,
        options: {
          projectId: "3ab5de1807b141cc1972fe8125ba600f",
        },
      }),
    ],
    [],
  );

  return (
    <SolanaWeb3Context.Provider value={{ network, connection }}>
      <ConnectionProvider endpoint={SOLANA_ENDPOINT}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </SolanaWeb3Context.Provider>
  );
};

export const useSolanaWeb3 = () => {
  const context = useContext(SolanaWeb3Context);
  if (!context) {
    throw new Error("useSolanaWeb3 must be used within a SolanaWeb3Provider");
  }

  return context;
};
