import { FC } from "react";
import { NextSeo } from "next-seo";
import Head from "next/head";

export interface SeoProps {
  overrideSiteName?: string;
  overrideUrl?: string;
  overrideTitle?: string;
  overrideDescription?: string;
  overrideImageUrl?: string;
}

export const SeoComponent: FC<SeoProps> = (props) => {
  const siteName = props.overrideSiteName || "unpump.fun";
  const url = props.overrideUrl || `https://${siteName}/`;
  const title = props.overrideTitle || "unpump";
  const description =
    props.overrideDescription ||
    "sell coins, earn rewards, pawer up for purr-v-purr and the journey to hypurrflow!";
  const imageUrl = props.overrideImageUrl || `${url}images/seo.png`;

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={`${url}`}
        openGraph={{
          type: "website",
          url,
          title,
          description,
          locale: "en_US",
          images: [
            {
              url: imageUrl,
              width: 1200,
              height: 630,
              alt: title,
            },
          ],
          siteName: siteName,
        }}
        twitter={{
          site: siteName,
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <link
          rel="icon"
          type="image/png"
          href="/favicon/favicon-96x96.png"
          sizes="96x96"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <meta name="language" content="EN" />
        <meta name="title" content={title} />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="unpump, fun, unpumpdotfun, unpump.fun, sell, dump, trade, swap, shitcoin, meme, memecoin, crypto, solana, pumpfun, pumpdotfun, pump.fun, solscan, trump, elon, rug, hack, lost money, money, hype, hyperliquid, hyper, purr, hypurr, purge, purrge, earn, rewards, liquidity, DeFi, collective, hypurrflow, refurral, purr-v-purr"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />

        <meta name="title" content={title} />
        <meta
          name="description"
          content="sell coins, earn rewards, pawer up for purr-v-purr and the journey to hypurrflow!"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        {/*  Twitter needs addition declarations */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageUrl} />
      </Head>
    </>
  );
};
