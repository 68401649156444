import {
  FC,
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSolanaSigner } from "./useSolanaSigner";
import { TokenInfo } from "@/lib/entities/token.entity";

type SolanaTokenDataContextType = {
  getTokenInfo(mint: string): Promise<TokenInfo>;
};

const CACHE_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes

export const SolanaDataContext =
  createContext<SolanaTokenDataContextType>(null);
export const useSolanaData = () => {
  const context = useContext(SolanaDataContext);
  if (!context) {
    throw new Error("useSolanaData must be used within a SolanaDataProvider");
  }

  return context;
};

export const SolanaDataProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [jupiterTokenMap, setJupiterTokenMap] = useState<Map<string, any>>(
    new Map(),
  );
  const [cache, setCache] = useState<
    Map<string, { data: TokenInfo; timestamp: number }>
  >(new Map());

  const { signerService } = useSolanaSigner();

  const getTokenInfo = useCallback(
    async (mint: string): Promise<TokenInfo> => {
      const now = Date.now();

      // Check in-memory cache
      const cachedEntry = cache.get(`${mint}`);
      if (cachedEntry && now - cachedEntry.timestamp < CACHE_EXPIRATION_MS) {
        return cachedEntry.data;
      }

      // Check jupiterTokenMap
      const jupiterData = jupiterTokenMap.get(mint);
      if (jupiterData) {
        const tokenInfo = {
          address: mint,
          name: jupiterData.name,
          symbol: jupiterData.symbol,
          icon: jupiterData.logoURI,
          decimals: jupiterData.decimals,
        } as TokenInfo;

        // Update cache
        setCache((prev) =>
          new Map(prev).set(mint, { data: tokenInfo, timestamp: now }),
        );
        return tokenInfo;
      }

      // Fetch from Solscan if signerService is available
      if (!signerService.solana) {
        return null;
      }

      const solscanData = await signerService.solana.getSolscanTokenPrice(mint);
      const tokenInfo = {
        address: mint,
        name: solscanData?.data?.name,
        symbol: solscanData?.data?.symbol,
        icon: solscanData?.data?.icon,
        decimals: solscanData?.data?.decimals,
        price: solscanData?.data?.price,
      } as TokenInfo;

      // Update cache
      setCache((prev) =>
        new Map(prev).set(mint, { data: tokenInfo, timestamp: now }),
      );
      return tokenInfo;
    },
    [cache, jupiterTokenMap, signerService],
  );

  const getJupiterTokenMap = useCallback(() => {
    const data = new Map();
    const portfolioTokens = JSON.parse(
      localStorage.getItem("jupiter-terminal-token-portfolio-list"),
    ) as any;

    (portfolioTokens?.data || []).forEach((token: any) => {
      data.set(token.address, token);
    });

    setJupiterTokenMap(data);
  }, []);

  useEffect(() => {
    getJupiterTokenMap();
  }, [getJupiterTokenMap]);

  return (
    <SolanaDataContext.Provider value={{ getTokenInfo }}>
      {children}
    </SolanaDataContext.Provider>
  );
};
