import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";

import { Token as SplToken, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import BN from "bn.js";
import {
  CacheUSDValue,
  IAccountsBalance,
  JupPriceResponse,
  ParsedTokenData,
  SolscanAccountDataInfo,
} from "@/types/solana-signer.entity";
import * as Token2022 from "@solana/spl-token-0.4";
import { AppNumber } from "../providers/math/app-number.provider";

const authHeader =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhtdGpnc250dGdkdXFkaWJzeW9iIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMxNjI1NDcsImV4cCI6MjA0ODczODU0N30.h_0PjUImhVykc5n73lmQqmdjMDbR4aFTBaW2JA78tD4";

const TOKEN_2022_PROGRAM_ID = new PublicKey(
  "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
);

export const THRESHOLD_TO_CLOSE = 0.05;
export const LIMIT_PER_BATCH = 8;
export const SUPABASE_PROTOCOL =
  "https://hmtjgsnttgduqdibsyob.supabase.co/functions/v1/terminal";
const REFERRAL_ACCOUNT = "unpk3UVoTxpabLCmcX7zFebxYDcAzzVUy8T7UvG4ewr";

export const getSolscanTokenPrice = async (address: string) => {
  const url = `${SUPABASE_PROTOCOL}/api/solscan/token/${address}`;
  const buildUrl = async () =>
    (await (
      await fetch(url, {
        headers: {
          Authorization: authHeader,
        },
      })
    ).json()) as unknown as SolscanAccountDataInfo;
  return (await buildUrl()) as unknown as SolscanAccountDataInfo;
};

export class SolanaSignerService {
  constructor(private readonly connection: Connection) {}

  private async handleFetchJupiterTokenPrice(ids: string[]) {
    const buildUrl = async () =>
      (
        await fetch(
          `${SUPABASE_PROTOCOL}/api/jupiter/tokens/prices?ids=${ids.join(",")}`,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        )
      ).json();

    return buildUrl();
  }

  public async getSolscanTokenPrice(address: string) {
    const url = `${SUPABASE_PROTOCOL}/api/solscan/token/${address}`;
    const buildUrl = async () =>
      (await (
        await fetch(url, {
          headers: {
            Authorization: authHeader,
          },
        })
      ).json()) as unknown as SolscanAccountDataInfo;
    return (await buildUrl()) as unknown as SolscanAccountDataInfo;
  }

  private async getPriceFromJupAPI(addresses: string[]) {
    const { data }: { data: JupPriceResponse } =
      await this.handleFetchJupiterTokenPrice(addresses);

    const nowTimestamp = new Date().getTime();
    return addresses.reduce<{
      result: Record<string, CacheUSDValue>;
      failed: string[];
    }>(
      (accValue, address, idx) => {
        const priceForAddress = data[address];
        if (!priceForAddress) {
          return {
            ...accValue,
            failed: [...accValue.failed, addresses[idx]],
          };
        }

        return {
          ...accValue,
          result: {
            ...accValue.result,
            [priceForAddress.id]: {
              usd: priceForAddress.price,
              timestamp: nowTimestamp,
            },
          },
        };
      },
      { result: {}, failed: [] }
    );
  }

  private getProgramId(type: string) {
    switch (type) {
      case "spl-token":
        return TOKEN_PROGRAM_ID.toBase58();
      case "spl-token-2022":
        return TOKEN_2022_PROGRAM_ID.toBase58();
      default:
        throw new Error("Invalid token type");
    }
  }

  private async buildTransferToken(
    transferAmount: number,
    decimals: number,
    mint: PublicKey,
    sender: PublicKey,
    receiver: PublicKey
  ): Promise<TransactionInstruction[]> {
    const ixs: TransactionInstruction[] = [];
    const sourceTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      sender,
      false
    );
    const destinationTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      receiver,
      false
    );

    const accountInfo = await this.connection.getAccountInfo(
      new PublicKey(destinationTokenAccount)
    );

    if (!accountInfo) {
      ixs.push(
        SplToken.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mint,
          destinationTokenAccount,
          receiver,
          sender
        )
      );
    }

    // if the account is empty, create it
    ixs.push(
      Token2022.createTransferInstruction(
        sourceTokenAccount,
        destinationTokenAccount,
        sender,
        Number((transferAmount * 10 ** decimals).toFixed(0)),
        [],
        TOKEN_PROGRAM_ID
      )
    );

    console.log("have created transfer instruction", ixs.length);
    return ixs;
  }

  private buildBurnToken2022Instruction(
    burnAmount: number,
    decimals: number,
    mint: PublicKey,
    sender: PublicKey
  ): TransactionInstruction {
    const sourceTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      sender,
      false,
      TOKEN_2022_PROGRAM_ID
    );

    return Token2022.createBurnCheckedInstruction(
      sourceTokenAccount,
      mint,
      sender,
      Number((burnAmount * 10 ** decimals).toFixed(0)),
      decimals,
      [],
      TOKEN_2022_PROGRAM_ID
    );
  }

  private buildBurnTokenInstruction(
    burnAmount: number,
    decimals: number,
    mint: PublicKey,
    sender: PublicKey
  ): TransactionInstruction {
    const sourceTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      sender
    );

    return Token2022.createBurnInstruction(
      sourceTokenAccount,
      mint,
      sender,
      Number((burnAmount * 10 ** decimals).toFixed(0)),
      []
    );
  }

  private async buildTransferToken2022Instruction(
    transferAmount: number,
    decimals: number,
    mint: PublicKey,
    sender: PublicKey,
    receiver: PublicKey
  ): Promise<TransactionInstruction[]> {
    const ixs: TransactionInstruction[] = [];
    const sourceTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      sender,
      false,
      TOKEN_2022_PROGRAM_ID
    );
    const destinationTokenAccount = Token2022.getAssociatedTokenAddressSync(
      mint,
      receiver,
      false,
      TOKEN_2022_PROGRAM_ID
    );

    const accountInfo = await this.connection.getAccountInfo(
      new PublicKey(destinationTokenAccount)
    );

    if (!accountInfo) {
      ixs.push(
        SplToken.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_2022_PROGRAM_ID,
          mint,
          destinationTokenAccount,
          receiver,
          sender
        )
      );
    }

    // if the account is empty, create it
    ixs.push(
      Token2022.createTransferCheckedInstruction(
        sourceTokenAccount,
        mint,
        destinationTokenAccount,
        sender,
        Number((transferAmount * 10 ** decimals).toFixed(0)),
        decimals,
        [],
        TOKEN_2022_PROGRAM_ID
      )
    );

    return ixs;
  }

  public async getTokenAccounts(walletAddress: string) {
    const publicKey = new PublicKey(walletAddress);
    if (!publicKey) return {};

    const [tokenAccounts, token2022Accounts] = await Promise.all(
      [TOKEN_PROGRAM_ID, TOKEN_2022_PROGRAM_ID].map((tokenProgramId) =>
        this.connection.getParsedTokenAccountsByOwner(publicKey, {
          programId: tokenProgramId,
        })
      )
    );

    const data = tokenAccounts.value.concat(token2022Accounts.value);
    const accountWithToken2022WithheldFee: ParsedTokenData[] =
      await Promise.all(
        data.map(async (item: any) => {
          return {
            ...item,
            withheldBalance:
              item.account.data.program === "spl-token-2022"
                ? Token2022.getTransferFeeAmount(
                    await Token2022.getAccount(
                      this.connection,
                      item.pubkey,
                      "confirmed",
                      TOKEN_2022_PROGRAM_ID
                    )
                  )?.withheldAmount.toString() || "0"
                : "0",
          };
        })
      );

    const accounts = accountWithToken2022WithheldFee.reduce(
      (acc, item: ParsedTokenData) => {
        acc[item.account.data.parsed.info.mint] = {
          mint: item.account.data.parsed.info.mint,
          program: this.getProgramId(item.account.data.program),
          programType: item.account.data.program,
          balance: item.account.data.parsed.info.tokenAmount.uiAmountString,
          balanceLamports: new BN(item.account.lamports),
          pubkey: item.pubkey,
          withheldBalance: item.withheldBalance.toString(),
          pubkeyAddress: item.pubkey.toBase58(),
          decimals: item.account.data.parsed.info.tokenAmount.decimals,
          isFrozen: item.account.data.parsed.info.state === 2, // 2 is frozen
        };
        return acc;
      },
      {} as Record<string, IAccountsBalance>
    );

    const accountsWithUSDValue = await this.getPriceFromJupAPI(
      Object.keys(accounts)
    );

    const solscanResponse = await Promise.all(
      Object.keys(accounts).map(async (address) => {
        const data = await this.getSolscanTokenPrice(address);
        if (!data.success) return null;
        return data;
      })
    );

    const solscanData = solscanResponse
      .filter((t) => t !== null)
      .reduce<{
        [key: string]: SolscanAccountDataInfo;
      }>((accValue, data) => {
        if (!data) return accValue;
        return {
          ...accValue,
          [data.data.address]: data,
        };
      }, {});

    const accountsWithUSDBalance = Object.keys(accounts).reduce<{
      accounts: Record<
        string,
        IAccountsBalance & {
          usdBalance: string;
          usd: any;
          solscanData: SolscanAccountDataInfo;
        }
      >;
      accountsWithUSDValue: Record<string, CacheUSDValue>;
    }>(
      (accValue, address) => {
        const account = accounts[address];
        const usdValue =
          accountsWithUSDValue.result[address] ||
          (solscanData?.[address]?.data.price
            ? {
                usd: solscanData[address].data.price,
                timestamp: new Date().getTime(),
              }
            : null);
        if (!usdValue) {
          return {
            accounts: {
              ...accValue.accounts,
              [address]: {
                ...account,
                usd: 0,
                usdBalance: "0",
                solscanData: solscanData?.[address],
              },
            },
            accountsWithUSDValue: accValue.accountsWithUSDValue,
          };
        }

        return {
          accounts: {
            ...accValue.accounts,
            [address]: {
              ...account,
              usd: usdValue.usd,
              solscanData: solscanData?.[address],
              usdBalance: AppNumber.from(account.balance || 1)
                .multiply(AppNumber.from(usdValue.usd || 1))
                .toString(),
            },
          },
          accountsWithUSDValue: {
            ...accValue.accountsWithUSDValue,
            [address]: usdValue,
          },
        };
      },
      { accounts: {}, accountsWithUSDValue: {} }
    );

    return {
      accounts,
      accountsWithUSDValue,
      accountsWithUSDBalance,
    };
  }

  public async getAvailableToCloseAccounts(walletAddress: string) {
    const accountsData = await this.getTokenAccounts(walletAddress);
    const accounts = accountsData.accountsWithUSDBalance.accounts;

    const vacantAccounts = Object.entries(accounts).map(
      ([_, account]) => account
    );

    const solBalances = vacantAccounts.map(async (account) => {
      const accountInfo = await this.connection.getAccountInfo(account.pubkey);
      return accountInfo ? accountInfo.lamports / 1e9 : 0;
    });

    const totalBalances = (await Promise.all(solBalances)).reduce(
      (acc, balance) => acc + balance,
      0
    );

    return {
      totalBalances,
      vacantAccounts,
      accountsWithUSDBalance: accountsData.accountsWithUSDBalance.accounts,
    };
  }

  private buildMemoInstruction(publicKey: PublicKey, message: string) {
    return new TransactionInstruction({
      keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
      data: Buffer.from(message, "utf-8"),
      programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
    });
  }

  private buildTransferInstruction(
    transferAmount: number,
    sender: PublicKey,
    receiver: PublicKey
  ) {
    return SystemProgram.transfer({
      fromPubkey: sender,
      toPubkey: receiver,
      lamports: Number(Number(transferAmount * LAMPORTS_PER_SOL).toFixed(0)), // Convert transferAmount to lamports
    });
  }

  public isFtOrEmptyNFT(
    account: IAccountsBalance & { usd: any; usdBalance: string }
  ) {
    return (
      Number(account.balance) === 0 ||
      (account.decimals > 0 &&
        Number(account.balance) > 0 &&
        Number(account.usdBalance) > 0 &&
        Number(account.usdBalance) < THRESHOLD_TO_CLOSE)
    );
  }
  public isClaimable(
    account: IAccountsBalance & { usd: any; usdBalance: string },
    forceMintToClaim?: string
  ) {
    return (
      (!account.isFrozen && // Skip frozen accounts
        Number(account.usdBalance) > 0 &&
        forceMintToClaim === account.mint) || // Include only the specified mint
      this.isFtOrEmptyNFT(account)
    );
  }

  public getClaimableAccounts(
    accounts: [string, IAccountsBalance & { usd: any; usdBalance: string }][],
    forceMintToClaim?: string
  ) {
    return accounts.filter(([_, account]) =>
      this.isClaimable(account, forceMintToClaim)
    );
  }

  public async closeAccounts(
    vacantAccounts: [
      string,
      IAccountsBalance & { usd: any; usdBalance: string },
    ][],
    walletAddress: string,
    feeRate: number = 0.2
  ): Promise<TransactionInstruction[]> {
    let solClaimed = 0;
    const closeInstructions: TransactionInstruction[] = []; // Batches of instructions

    closeInstructions.push(
      this.buildMemoInstruction(new PublicKey(walletAddress), "unpump.fun")
    );

    for (const [mintAddress, account] of vacantAccounts) {
      let solToClaim = account.balanceLamports.toNumber();

      // Add burn instruction for non-empty accounts (only for non-NFTs)
      if (Number(account.balance) > 0) {
        let closeInstruction;
        if (account.programType === "spl-token") {
          closeInstruction = this.buildBurnTokenInstruction(
            Number(account.balance),
            account.decimals,
            new PublicKey(mintAddress),
            new PublicKey(walletAddress)
          );
        } else {
          closeInstruction = this.buildBurnToken2022Instruction(
            Number(account.balance),
            account.decimals,
            new PublicKey(mintAddress),
            new PublicKey(walletAddress)
          );
        }
        closeInstructions.push(closeInstruction);
      }

      // Handle Token-2022 specific logic
      if (
        account.programType === "spl-token-2022" &&
        Number(account.withheldBalance) > 0
      ) {
        closeInstructions.push(
          Token2022.createHarvestWithheldTokensToMintInstruction(
            new PublicKey(mintAddress),
            [
              account.pubkey,
              Token2022.getAssociatedTokenAddressSync(
                new PublicKey(mintAddress),
                new PublicKey(REFERRAL_ACCOUNT),
                false,
                TOKEN_2022_PROGRAM_ID
              ),
            ]
          )
        );
      }

      // Add close account instruction
      const closeInstruction = SplToken.createCloseAccountInstruction(
        new PublicKey(account.program),
        account.pubkey,
        new PublicKey(walletAddress),
        new PublicKey(walletAddress),
        []
      );
      closeInstructions.push(closeInstruction);
      solClaimed += solToClaim;
    }

    // Handle remaining tokens in the last batch
    closeInstructions.push(
      this.buildTransferInstruction(
        (solClaimed / 1e9) * feeRate,
        new PublicKey(walletAddress),
        new PublicKey(REFERRAL_ACCOUNT)
      )
    );
    closeInstructions.push(
      this.buildMemoInstruction(new PublicKey(walletAddress), "unpump.fun")
    );

    return closeInstructions;
  }
}
