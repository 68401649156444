import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC, ReactNode, useState } from "react";
import { Header } from "@/ui/Components/Header/Header";
import { useSupabase } from "@/hooks/useSupabase";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
const Footer = dynamic(() => import("@/ui/Components/Footer"), { ssr: false });

export const RegularPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showSpin, setShowSpin] = useState(true);
  const router = useRouter();
  const { myTotalScore } = useSupabase();

  return (
    <Box minHeight="100vh" position="relative" overflow="hidden">
      <Flex
        display={showSpin && router.pathname !== "/spin" ? "flex" : "none"}
        flexDir="column"
        position="fixed"
        zIndex={3}
        pt="8px"
        pb="4px"
        px={{ base: "12px", lg: "16px" }}
        borderRadius="1px"
        border="1px solid"
        borderColor="primary.yellow"
        bg="black"
        right="50px"
        top="50%"
        transform="translateY(-50%)"
        cursor="pointer"
        onClick={() => router.push("/spin")}
        _hover={{ borderColor: "primary.red.hover" }}
        role="group"
        animation={myTotalScore.chip >= 10 ? `blink 1s infinite` : "none"}
        w={{ base: "119px", lg: "auto" }}
        h={{ base: "113px", lg: "auto" }}
      >
        <Box
          position="absolute"
          top="7px"
          left="10px"
          cursor="pointer"
          width={{ base: "14px", lg: "16px" }}
          height={{ base: "14px", lg: "16px" }}
          borderBottom="1px solid transparent"
          _hover={{ borderColor: "primary.red" }}
          onClick={(e) => {
            e.stopPropagation();
            setShowSpin(false);
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.99958 2.00049H1.99976V4.00104H3.99958V2.00049Z"
              fill="#EF2929"
            />
            <path
              d="M9.99909 2.00049H7.99927V4.00104H9.99909V2.00049Z"
              fill="#EF2929"
            />
            <path d="M11.9988 0H9.99902V2.00053H11.9988V0Z" fill="#EF2929" />
            <path d="M1.99983 0H0V2.00053H1.99983V0Z" fill="#EF2929" />
            <path
              d="M3.99958 7.99902H1.99976V9.99955H3.99958V7.99902Z"
              fill="#EF2929"
            />
            <path
              d="M1.99983 9.99951H0V12.0001H1.99983V9.99951Z"
              fill="#EF2929"
            />
            <path
              d="M9.99909 7.99902H7.99927V9.99955H9.99909V7.99902Z"
              fill="#EF2929"
            />
            <path
              d="M11.9988 9.99951H9.99902V12.0001H11.9988V9.99951Z"
              fill="#EF2929"
            />
            <path
              d="M7.99916 4.00146H3.99951V8.00255H7.99916V4.00146Z"
              fill="#EF2929"
            />
          </svg>
        </Box>
        <Image
          mx="auto"
          mt="10px"
          src="/icons/spin.svg"
          w={{ base: "68px", lg: "100px" }}
          h={{ base: "68px", lg: "100px" }}
          objectFit="cover"
          animation={myTotalScore.chip >= 10 ? `pulse 3s infinite` : "none"}
        ></Image>
        <Flex justifyContent="center">
          <Text
            size={{ base: "lg", lg: "xl" }}
            color="primary.yellow"
            cursor="pointer"
            _groupHover={{ color: "primary.red.hover" }}
            lineHeight={{ base: "18px", lg: "20px" }}
            animation={myTotalScore.chip >= 10 ? `blink 1s infinite` : "none"}
          >
            lucky spin
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir="column" zIndex={2} position="relative" minHeight="100vh">
        <Header />
        <Flex flexDirection="column" width="full" as="main" flex={1}>
          {children}
        </Flex>
        <Footer />
      </Flex>
      <Image
        src="/images/bombff.png"
        position="absolute"
        w="400px"
        bottom="0"
        zIndex={1}
        objectFit="cover"
        display={{ base: "none", lg: "block" }}
      />
    </Box>
  );
};
