import { Text, Flex, Image, useDisclosure, Divider } from "@chakra-ui/react";
import { type FC } from "react";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "../PopoverModal";
import { useWalletConnect } from "@/hooks/useWalletConnect";
import { useSupabase } from "@/hooks/useSupabase";

export const HeaderChainSelect: FC = () => {
  const chainMenuDisclosure = useDisclosure();
  const { address } = useWalletConnect();
  const { auth } = useSupabase();

  return (
    <PopoverModal
      isOpen={chainMenuDisclosure.isOpen}
      onClose={chainMenuDisclosure.onClose}
      order={3}
      position={{ base: "unset" }}
      w={{ base: "unset", xl: "unset", lg: "unset" }}
      top={{ base: "unset", lg: "unset" }}
      bottom={{ base: "unset", lg: "unset" }}
      boxShadow="unset"
    >
      <PopoverModalTrigger>
        <Flex
          p="4px"
          gap="4px"
          borderRadius="2px"
          border="1px solid"
          borderColor={
            chainMenuDisclosure.isOpen ? "primary.red.hover" : "stroke"
          }
          alignItems="center"
          cursor="url('/icons/cursor-pointer.svg'), pointer"
          onClick={() => chainMenuDisclosure.onToggle()}
          h={address && auth?.user?.id ? "24px" : "40px"}
          _hover={{ borderColor: "primary.red.hover" }}
          role="group"
        >
          <Image
            src="/images/solana.png"
            w={address && auth?.user?.id ? "16px" : "32px"}
            h={address && auth?.user?.id ? "16px" : "32px"}
            borderRadius="16px"
          />
          <Image
            w={address && auth?.user?.id ? "16px" : "20px"}
            h={address && auth?.user?.id ? "16px" : "20px"}
            src={
              chainMenuDisclosure.isOpen
                ? "/icons/dropdown-hover.svg"
                : "/icons/dropdown.svg"
            }
            transition="transform 0.3s linear"
            transform={`rotate(${chainMenuDisclosure.isOpen ? 180 : 0}deg)`}
            _groupHover={{
              content: 'url("/icons/dropdown-hover.svg")',
            }}
          />
        </Flex>
      </PopoverModalTrigger>
      <PopoverModalContent
        p="0px"
        right={{ base: "32px", lg: "0px" }}
        top="24px"
        width="130px"
        overflow="hidden"
        display="flex"
        flexDir="column"
        gap="5px"
      >
        <Flex
          w="full"
          h="40px"
          px="16px"
          py="8px"
          alignItems="center"
          justifyContent="space-between"
          cursor="url('/icons/cursor-pointer.svg'), pointer"
          role="group"
        >
          <Image
            src="/images/solana.png"
            w="24px"
            h="24px"
            borderRadius="24px"
          />
          <Text
            size="xl"
            color="secondary.gray"
            _groupHover={{ color: "primary.red.hover" }}
          >
            Solana
          </Text>
        </Flex>
        {/* <Divider w="80%" mx="auto" color="stroke" />
        <Flex
          w="full"
          px="16px"
          py="8px"
          alignItems="center"
          justifyContent="space-between"
          _hover={{ bg: "stroke" }}
          cursor="pointer"
        >
          <Image w="24px" h="24px" src="/icons/base.svg" borderRadius="36px" />
          <Text size="xl">Base</Text>
        </Flex> */}
      </PopoverModalContent>
    </PopoverModal>
  );
};
