import "@jup-ag/terminal/css";

import "reflect-metadata";

import { NextPage } from "next";
import { ReactNode } from "react";
import type { AppProps } from "next/app";

import theme from "@/theme";
import { Inter } from "next/font/google";
import { ToastContainer } from "react-toastify";
import {
  ChakraProvider,
  cookieStorageManagerSSR,
  localStorageManager,
} from "@chakra-ui/react";

import { OnLoadModule } from "@/ui/LifeCycle/OnLoadModule";
import { RegularPageLayout } from "@/ui/Components/Layouts/RegularPageLayout";

import "@/styles/globals.css";
import "tailwindcss/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import { Fonts } from "@/theme/fonts";
import { SolanaWeb3Provider } from "@/hooks/SolanaWeb3Provider";
import { SupabaseProvider } from "@/hooks/useSupabase";

import "@jup-ag/terminal/css";
import { TokenContextProvider } from "@/hooks/TokenContextProvider";
import { SolanaSignerProvider } from "@/hooks/useSolanaSigner";
import { SolanaDataProvider } from "@/hooks/SolanaTokenDataProvider";
import { ActivityStoreProvider } from "@/ui/Components/Header/TxActivity";
import { SeoComponent } from "@/ui/Components/SEO";
import { SolanaJupiterProvider } from "@/hooks/SolanaWeb3JupiterProvider";

const inter = Inter({ subsets: ["latin"] });
type Props = AppProps & { Component: NextPage };
export default function App({ Component, pageProps, ...props }: Props) {
  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => (
      <RegularPageLayout>
        <SeoComponent />
        {page}
      </RegularPageLayout>
    ));

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <ChakraProvider
        theme={theme}
        colorModeManager={
          typeof (props as any)?.cookies === "string"
            ? cookieStorageManagerSSR((props as any)?.cookies)
            : localStorageManager
        }
      >
        <Fonts />
        <TokenContextProvider>
          <SolanaWeb3Provider>
            <SolanaJupiterProvider>
              <SolanaSignerProvider>
                <SupabaseProvider>
                  <OnLoadModule />
                  {/* {getHead(pageProps)} */}
                  <SolanaDataProvider>
                    <ActivityStoreProvider>
                      {getLayout(<Component {...pageProps} />)}
                    </ActivityStoreProvider>
                  </SolanaDataProvider>
                  <ToastContainer autoClose={4000} />
                </SupabaseProvider>
              </SolanaSignerProvider>
            </SolanaJupiterProvider>
          </SolanaWeb3Provider>
        </TokenContextProvider>
      </ChakraProvider>
    </>
  );
}
