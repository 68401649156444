import { SupabaseClient } from "@supabase/supabase-js";
import { SUPABASE_FUNCTION_PROTOCOL } from "../constants/supabase";

export class SupabaseService {
  constructor(protected readonly instance: SupabaseClient) {}

  /**
   * Generate the function URL
   * This function will generate the function URL interacting via Supabase invoke function
   * based on the slug
   * @param slug The function slug
   * @returns The function URL
   */
  protected gen(slug: string): string {
    return SUPABASE_FUNCTION_PROTOCOL + slug;
  }
}
