import { Image, Flex, Tooltip } from "@chakra-ui/react";
import { FC, ReactNode, useState } from "react";

export const TooltipInfo: FC<{
  label: string;
  children?: ReactNode;
  showInfoIcon?: boolean;
}> = ({ children, label, showInfoIcon = true }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      label={label}
      isOpen={isOpen}
      placement="bottom"
      onClose={() => setIsOpen(false)}
    >
      <Flex
        gap="4px"
        alignItems="center"
        justifyContent="center"
        onMouseEnter={() => !showInfoIcon && setIsOpen(true)}
        onMouseLeave={() => !showInfoIcon && setIsOpen(false)}
      >
        {children && children}
        {showInfoIcon && (
          <Image
            src={isOpen ? "/icons/info-hover.svg" : "/icons/info.svg"}
            w="16px"
            h="16px"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          />
        )}
      </Flex>
    </Tooltip>
  );
};
