import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";

export interface UserLevel {
  id: number; // bigint
  createdAt: string; // timestamp with time zone, represented as an ISO 8601 string
  userId: string; // uuid
  lastestDumpTransactionId: number; // bigint
  totalVolumeUsd: number; // double precision
  lvl: number; // bigint
  startDumpTransactionId: number; // bigint
}

export interface UserLevelInfo {
  cumulative_usd: number;
  lastest_dump_transaction_id: number;
  locked_un: number;
  lvl: number;
  need_volume_usd: number;
  nuke_credit: number;
  nuke_damage: number;
  start_dump_transaction_id: number;
  total_volume_usd: number;
  user_id: string;
  claimed_at: Date;
  try_double_reward: number;
  claimed_nuke_credit: number;
  reward_nuke_credit: number;
}

export interface UserBalance {
  created_at: Date;
  id: number;
  nuke_chip: number;
  nuke_credit: number;
  nuke_damage: number;
  user_id: string;
}

export class PurgeProfileSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    private readonly publicInstance: SupabaseClient,
    private readonly userId: string
  ) {
    super(instance);
  }

  public async storeTxHash(txHash: string): Promise<void> {
    await this.instance
      .from("tx_hashes")
      .insert([{ user_id: this.userId, tx_hash: txHash }]);
  }

  public async getUserLevel(): Promise<UserLevel> {
    const { data, error } = await this.publicInstance
      .from("user_level")
      .select("*")
      .eq("user_id", this.userId);

    if (error || !data || data.length === 0) {
      console.warn("error when fetching user level", error);
      return null;
    }

    return data[0];
  }

  public async getUserLevelInfo(input_level = 0): Promise<UserLevelInfo[]> {
    const { data, error } = await this.publicInstance.rpc(
      "get_user_level_info",
      {
        input_level,
        input_user_id: this.userId,
      }
    );

    if (error || !data || !data.length) {
      console.warn("error when fetching user level info", error);
      return [];
    }

    return data;
  }

  public async claimReward(level: number, bet_double_reward = false) {
    const { data, error } = await this.instance.functions.invoke(
      "claim-nuke-credit",
      {
        body: {
          bet_double_reward,
          level,
        },
      }
    );

    if (error || !data) {
      console.warn("error when claiming reward", error);
      throw new Error("error when claiming reward");
    }

    return data;
  }

  public async getUserBalance(): Promise<UserBalance> {
    const { data, error } = await this.instance
      .from("user_balance")
      .select("*")
      .eq("user_id", this.userId);

    if (error || !data || data.length === 0) {
      console.warn("error when fetching user balance", error);
      return null;
    }

    return data[0];
  }
}
