import {
  FC,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { Flex, Link, Image, Text, Box, chakra } from "@chakra-ui/react";
import { useSupabase } from "@/hooks/useSupabase";
import {
  DumpTransaction,
  exampleDumpTransaction,
} from "@/lib/services/purge.supabase.service";
import { useInterval } from "usehooks-ts";
import { createRoot } from "react-dom/client";
import useIsMobile from "@/hooks/useIsMobile";
import { TokenInfo } from "@/lib/entities/token.entity";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { useSolanaData } from "@/hooks/SolanaTokenDataProvider";
import { UserPlaySpinReward } from "@/lib/services/spin.supabase.service";

const AnimatedElement: FC<{
  transactionId: string;
  tokenData: TokenInfo;
  transaction: DumpTransaction;
  spinTransaction: UserPlaySpinReward;
  onAnimationStart?(id: string): void;
  onAnimationEnd(id: string): void;
}> = ({ spinTransaction, transaction, tokenData }) => {
  return (
    <Box
      bg="black"
      className="tx-activity-item"
      animation="horizontal-shaking 0.5s"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="4px"
        px="4px"
        py="2px"
        border="1px solid"
        borderColor="white"
        borderRadius="1px"
        animation="mymove infinite 0.5s"
        _hover={{ borderColor: "#FF7F7F" }}
        cursor="url('/icons/cursor-pointer.svg'), pointer"
        onClick={() =>
          transaction && window.open(`https://solscan.io/tx/${transaction?.tx}`)
        }
      >
        <Text size="md">
          {spinTransaction
            ? spinTransaction.nickname
            : transaction?.nickname || ""}{" "}
          {spinTransaction ? (
            <chakra.span color="#EF2929">just won</chakra.span>
          ) : (
            <Link
              color="#EF2929"
              href={`https://solscan.io/tx/${transaction.tx}`}
              isExternal
            >
              nuked
            </Link>
          )}
        </Text>
        {spinTransaction ? (
          <>
            {spinTransaction.nuke_credit ? (
              <>
                <Text size="md" color="#FCE94F">
                  {AppNumber.from(
                    spinTransaction?.nuke_credit || 0
                  ).getDisplayedString()}
                </Text>
                <Image src="/icons/diamond.svg" h="16px" w="17px" />
              </>
            ) : null}
            {spinTransaction.nuke_credit && spinTransaction.usd ? (
              <Text size="md">and</Text>
            ) : null}
            {spinTransaction.usd ? (
              <>
                <Text size="md" color="#FCE94F">
                  {`${AppNumber.from(
                    spinTransaction?.usd || 0
                  ).getDisplayedString()}`}
                </Text>
                <Image src="/icons/gold.svg" h="16px" w="16px" />
              </>
            ) : null}
          </>
        ) : (
          <>
            <Text size="md" color="#FCE94F">
              {AppNumber.from(
                transaction?.from_amount || 0
              ).getDisplayedString()}
            </Text>
            <Image
              src={tokenData?.icon || "/images/avatar.png"}
              h="16px"
              w="16px"
            />
            <Text size="md" color="#FCE94F">
              {tokenData?.symbol}
            </Text>
            <Text size="md">for</Text>
            <Text size="md" color="#FCE94F">
              {`${AppNumber.from(transaction?.to_usd_value || 0).getDisplayedString()}`}
            </Text>
            <Image src="/icons/gold.svg" h="16px" w="16px" />
          </>
        )}
      </Flex>
    </Box>
  );
};

let intervalFn: any;

export const TxActivity = () => {
  const { getTokenInfo } = useSolanaData();
  const { publicService } = useSupabase();
  const containerRef = useRef(null);
  // const [pendingTx, setPendingTx] = useState<
  //   {
  //     rendered: boolean;
  //     tx: DumpTransaction;
  //     tokenData: TokenInfo;
  //     spinTransaction: UserPlaySpinReward;
  //   }[]
  // >([]);

  const { pendingTx, setPendingTx } = useActivityStore();

  const [scroll, setScroll] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const isMobile = useIsMobile();

  const handleAnimationEnd = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      containerRef.current.removeChild(element);
    }
  };

  const removeFirstElement = useCallback(() => {
    const als = isMobile ? 1 : 3;
    if (containerRef?.current?.childNodes?.length >= als) {
      const element = containerRef.current.firstElementChild;
      if (element) {
        containerRef.current.removeChild(element);
      }
    }
  }, [containerRef, isMobile]);

  const initShowingLatestTxs = useCallback(async () => {
    if (!publicService.purge || pendingTx.length) return;
    const latestTxs = await publicService.purge.fetchAllDumpTransactions(0, 3);
    for (const transaction of latestTxs) {
      const tokenData = await getTokenInfo(
        transaction.tx_type === "close_solana_account"
          ? "So11111111111111111111111111111111111111112"
          : transaction.from_token_address
      );

      setPendingTx((prev) => [
        ...prev,
        {
          tokenData,
          rendered: true,
          tx: transaction,
          spinTransaction: null,
        },
      ]);
    }
  }, [publicService, pendingTx]);

  useEffect(() => {
    initShowingLatestTxs();
  }, [publicService]);

  useEffect(() => {
    if (isMobile && containerRef?.current?.childNodes?.length > 1) {
      while (containerRef.current.childNodes.length > 1) {
        const element = containerRef.current.firstElementChild;
        if (element) {
          containerRef.current.removeChild(element);
        }
      }
    }
  }, [isMobile, containerRef]);

  useEffect(() => {
    if (!publicService.purge) return;
    publicService.purge.listenNewDumpTransactions(async (transaction) => {
      const tokenData = await getTokenInfo(
        transaction.tx_type === "close_solana_account"
          ? "So11111111111111111111111111111111111111112"
          : transaction.from_token_address
      );
      setPendingTx((prev) => [
        ...prev,
        {
          tokenData,
          rendered: false,
          tx: transaction,
          spinTransaction: null,
        },
      ]);
    });

    // setInterval(() => {
    //   if (containerRef.current) {
    //     setPendingTx((prev) => [
    //       ...prev,
    //       {
    //         rendered: false,
    //         tokenData: { symbol: "SOL", icon: "/images/avatar.png" } as any,
    //         spinTransaction: null,
    //         tx: { ...exampleDumpTransaction, from_amount: Math.random() * 100 },
    //       },
    //     ]);
    //   }
    // }, 5000);
  }, [publicService]);

  useEffect(() => {
    if (pendingTx.length && pendingTx[0].rendered) {
      if (!isShow) {
        setIsShow(true);
      }
      const newElement = document.createElement("div");
      const elementId = `transaction-${Math.random().toString()}`;
      newElement.id = elementId;
      removeFirstElement();
      containerRef.current.appendChild(newElement);
      const root = createRoot(newElement);
      const AnimatedComponent = () => (
        <AnimatedElement
          transactionId={elementId}
          transaction={pendingTx[0].tx}
          tokenData={pendingTx[0].tokenData}
          spinTransaction={pendingTx[0].spinTransaction}
          onAnimationEnd={handleAnimationEnd}
        />
      );
      root.render(<AnimatedComponent />);

      // remove first element from pendingTx
      setPendingTx((prev) => prev.slice(1));
    }
  }, [pendingTx, isShow]);

  useInterval(() => {
    if (pendingTx.length && !pendingTx[0].rendered) {
      setPendingTx((prev) => [
        {
          rendered: true,
          tx: prev[0].tx,
          tokenData: prev[0].tokenData,
          spinTransaction: prev[0].spinTransaction,
        },
        ...prev.slice(1),
      ]);
    }
  }, 1000);

  // const modify = useCallback(() => {
  //   if (pendingTx.length && !pendingTx[0].rendered) {
  //     setPendingTx((prev) => [
  //       { rendered: true, tx: prev[0].tx, tokenData: prev[0].tokenData },
  //       ...prev.slice(1),
  //     ]);
  //   }
  // }, [pendingTx]);

  // useEffect(() => {
  //   intervalFn && clearTimeout(intervalFn);
  //   intervalFn = new UtilsProvider().withInterval(() => modify(), 1000);

  //   return () => {
  //     intervalFn && clearTimeout(intervalFn);
  //   };
  // }, [pendingTx]);

  useEffect(() => {
    document.body.onscroll = () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
  }, []);

  return (
    <Box
      w="full"
      zIndex={900}
      bg="black"
      position={scroll ? "fixed" : "relative"}
      display={isShow ? "block" : "none"}
    >
      <Flex
        ref={containerRef}
        overflow="hidden"
        width="100%"
        h="40px"
        mx="auto"
        gap="16px"
        alignItems="center"
        justifyContent="center"
        w={{ base: "full", xl: "1066px" }}
        px={{ base: "16px", lg: "24px", xl: "40px" }}
      ></Flex>
    </Box>
  );
};

type ActivityStoreContextType = {
  pendingTx: {
    rendered: boolean;
    tx: DumpTransaction;
    tokenData: TokenInfo;
    spinTransaction: UserPlaySpinReward;
  }[];
  setPendingTx: Dispatch<
    SetStateAction<
      {
        rendered: boolean;
        tx: DumpTransaction;
        tokenData: TokenInfo;
        spinTransaction: UserPlaySpinReward;
      }[]
    >
  >;
  addPendingTx: (activity: {
    rendered: boolean;
    tx: DumpTransaction;
    tokenData: TokenInfo;
    spinTransaction: UserPlaySpinReward;
  }) => void;
};

const ActivityStoreContext = createContext<ActivityStoreContextType>({
  pendingTx: [],
  setPendingTx: () => {},
  addPendingTx: () => {},
});

export const useActivityStore = () => {
  const context = useContext(ActivityStoreContext);
  if (!context) {
    throw new Error(
      "useActivityStore must be used within a ActivityStoreContext"
    );
  }
  return context;
};

export const ActivityStoreProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pendingTx, setPendingTx] = useState<
    {
      rendered: boolean;
      tx: DumpTransaction;
      tokenData: TokenInfo;
      spinTransaction: UserPlaySpinReward;
    }[]
  >([]);

  const addPendingTx = useCallback(
    (activity: {
      rendered: boolean;
      tx: DumpTransaction;
      tokenData: TokenInfo;
      spinTransaction: UserPlaySpinReward;
    }) => {
      setPendingTx((prev) => [...prev, activity]);
    },
    []
  );

  return (
    <ActivityStoreContext.Provider
      value={{ pendingTx, setPendingTx, addPendingTx }}
    >
      {children}
    </ActivityStoreContext.Provider>
  );
};
