/* eslint-disable jsx-a11y/alt-text */
import { Text, Button, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect, useMemo } from "react";
import { useSupabase } from "@/hooks/useSupabase";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "../PopoverModal";
import { HeaderChainSelect } from "./HeaderChainSelect";
import { useWalletConnect } from "@/hooks/useWalletConnect";
import { EditProfileModal } from "../EditProfileModal";
import CountUp from "react-countup";
import HeaderHamburgerButton from "./HeaderHamburgerButton";
import { TooltipInfo } from "../TooltipInfo";

export const HeaderWalletButton: FC<{
  isOpenDropdown: boolean;
  onToggleDropdown: () => void;
}> = ({ isOpenDropdown, onToggleDropdown }) => {
  const walletProfileDisclosure = useDisclosure();
  const editProfileModalDisclosure = useDisclosure();
  const { user, auth, myTotalScore } = useSupabase();
  const {
    address,
    solBalance,
    isAppAuth,
    connected,
    handleLogin,
    connectSolana,
    disconnectSolana,
  } = useWalletConnect();

  const isLoading = false;

  const calculateDamageMitigation = (def: number) => {
    return Math.min(0.9, 0.15 * Math.log2(0.1 * def + 1)) * 100;
  };

  const calculateEvasionChance = (eva: number) => {
    return Math.min(0.3, 0.03 * Math.log2(1.5 * eva + 1)) * 100;
  };

  useEffect(() => {
    if (connected && !auth?.access_token) {
      handleLogin();
      return;
    }

    if (
      address &&
      connected &&
      auth?.user.address &&
      auth?.user.address !== address
    ) {
      console.log("disconnecting", { address, auth });
      disconnectSolana();
      return;
    }
  }, [connected, auth, address]);

  return (
    <>
      <Flex flexDir="column" gap="4px" justifySelf="flex-end">
        <Flex
          alignItems="center"
          gap="8px"
          position="relative"
          justifyContent="flex-end"
        >
          {address && auth?.user?.id ? (
            <PopoverModal
              isOpen={walletProfileDisclosure.isOpen}
              onClose={walletProfileDisclosure.onClose}
              position={{ base: "unset" }}
              w={{ base: "unset", xl: "unset", lg: "unset" }}
              top={{ base: "unset", lg: "unset" }}
              bottom={{ base: "unset", lg: "unset" }}
              boxShadow="unset"
              zIndex={9999}
            >
              <PopoverModalTrigger>
                <Flex
                  h="24px"
                  p="4px"
                  gap="10px"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="2px"
                  border="1px solid"
                  borderColor={
                    walletProfileDisclosure.isOpen
                      ? "primary.red.hover"
                      : "stroke"
                  }
                  cursor="url('/icons/cursor-pointer.svg'), pointer"
                  onClick={() => walletProfileDisclosure.onToggle()}
                  _hover={{ borderColor: "primary.red.hover" }}
                  role="group"
                >
                  <Image
                    w="16px"
                    h="16px"
                    src={user?.avatar_url || "/images/avatar.png"}
                  />
                  <Text
                    size="md"
                    color={
                      walletProfileDisclosure.isOpen
                        ? "primary.red.hover"
                        : "white"
                    }
                    _groupHover={{ color: "primary.red.hover" }}
                  >
                    {user?.nickname ||
                      `${address.substring(0, 5)}...${address.substring(address.length - 5, address.length)}`}
                  </Text>
                  <Image
                    w="16px"
                    h="16px"
                    src={
                      walletProfileDisclosure.isOpen
                        ? "/icons/dropdown-hover.svg"
                        : "/icons/dropdown.svg"
                    }
                    transition="transform 0.3s linear"
                    transform={`rotate(${walletProfileDisclosure.isOpen ? 180 : 0}deg)`}
                    _groupHover={{
                      content: 'url("/icons/dropdown-hover.svg")',
                    }}
                  />
                </Flex>
              </PopoverModalTrigger>
              <PopoverModalContent
                p="0px"
                right={{ base: "86px", lg: "54px" }}
                top="24px"
                width="140px"
                overflow="hidden"
                display="flex"
                flexDir="column"
              >
                <Flex w="full" h="40px" p="8px">
                  <Text color="secondary.yellow" size="xl">
                    {solBalance} SOL
                  </Text>
                </Flex>
                <Flex
                  w="full"
                  h="40px"
                  p="8px"
                  role="group"
                  cursor="url('/icons/cursor-pointer.svg'), pointer"
                  onClick={() => editProfileModalDisclosure.onOpen()}
                >
                  <Text
                    size="xl"
                    color="secondary.gray"
                    _groupHover={{ color: "primary.red.hover" }}
                  >
                    my profile
                  </Text>
                </Flex>
                <Flex
                  w="full"
                  h="40px"
                  p="8px"
                  role="group"
                  cursor="url('/icons/cursor-pointer.svg'), pointer"
                  onClick={async () => {
                    walletProfileDisclosure.onClose();
                    await disconnectSolana();
                  }}
                >
                  <Text
                    color="primary.red"
                    size="xl"
                    _groupHover={{ color: "primary.red.hover" }}
                  >
                    disconnect
                  </Text>
                </Flex>
                {editProfileModalDisclosure.isOpen && (
                  <EditProfileModal {...editProfileModalDisclosure} />
                )}
              </PopoverModalContent>
            </PopoverModal>
          ) : (
            <Button
              loadingText="Signing in..."
              isLoading={isLoading}
              size="md"
              onClick={() => {
                connectSolana();
              }}
            >
              connect wallet
            </Button>
          )}
          <HeaderChainSelect />
          <HeaderHamburgerButton
            display={{ lg: "none" }}
            onClick={() => onToggleDropdown()}
            isOpen={isOpenDropdown}
          ></HeaderHamburgerButton>
        </Flex>
        {connected && isAppAuth && (
          <Flex flexDir="column" gap="4px">
            {/* Top Stats: Nuke Damage, Nuke Credits, Chips */}
            <Flex alignItems="center" justifyContent="flex-end" flexWrap="wrap">
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label="nuke damage: volume on unpump"
                  showInfoIcon={false}
                >
                  <Image src="/icons/fire.svg" w="14px" h="14px" />
                  <Text size="md" color="secondary.orange">
                    <CountUp decimals={4} end={myTotalScore.damage || 0} />
                  </Text>
                </TooltipInfo>
              </Flex>
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label="nuke credits: unpump reward points"
                  showInfoIcon={false}
                >
                  <Image src="/icons/diamond.svg" w="17.06px" h="16px" />
                  <Text size="md" color="secondary.teal">
                    <CountUp decimals={4} end={myTotalScore.nuke || 0} />
                  </Text>
                </TooltipInfo>
              </Flex>
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label="chips: currency for mini-games"
                  showInfoIcon={false}
                >
                  <Image src="/icons/chip.svg" w="15px" h="16px" />
                  <Text size="md">
                    <CountUp decimals={4} end={myTotalScore.chip || 0} />
                  </Text>
                </TooltipInfo>
              </Flex>
            </Flex>

            {/* Bottom Stats: Level, Attack, Defense, Evasion */}
            <Flex alignItems="center" justifyContent="flex-end" flexWrap="wrap">
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label="user level: 1 level per 1000 nuke damage"
                  showInfoIcon={false}
                >
                  <Image src="/icons/level.svg" w="17px" h="16px" />
                  <Text size="md" color="white">
                    lv. <CountUp end={user?.level || 0} />
                  </Text>
                </TooltipInfo>
              </Flex>
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label="atk: attack damage before damage mitigation"
                  showInfoIcon={false}
                >
                  <Image src="/icons/atk.svg" w="17px" h="16px" />
                  <Text size="md" color="primary.red">
                    {user?.atk || 0}
                  </Text>
                </TooltipInfo>
              </Flex>
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label={`def: damage mitigation = ${calculateDamageMitigation(user?.def || 0)}%`}
                  showInfoIcon={false}
                >
                  <Image src="/icons/def.svg" w="15px" h="16px" />
                  <Text size="md" color="secondary.teal">
                    {user?.def || 0}
                  </Text>
                </TooltipInfo>
              </Flex>
              <Flex gap="4px" py="4px" px="6px" h="24px" alignItems="center">
                <TooltipInfo
                  label={`eva: evasion chance = ${calculateEvasionChance(user?.eva || 0)}%`}
                  showInfoIcon={false}
                >
                  <Image src="/icons/eva.svg" w="22px" h="16px" />
                  <Text size="md" color="primary.yellow">
                    {user?.eva || 0}
                  </Text>
                </TooltipInfo>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
