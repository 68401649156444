import { StyleFunctionProps } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

import { mode } from "@chakra-ui/theme-tools";

const generateThemeFromColorScheme = (props: StyleFunctionProps) => {
  const { colorScheme } = props;
  let event;

  switch (colorScheme) {
    case "empty":
      return {
        bg: "transparent",
        border: "none",
        _hover: { bg: "transparent" },
        _focus: { bg: "transparent" },
      };

    case "white":
      event = {
        bg: mode("neutral.stroke.light", "neutral.stroke.light")(props),
      };

      return {
        bg: mode("primary.black", "brand.primary")(props),
        color: mode("neutral.element.primary", "neutral.text.button")(props),
        _hover: event,
        _focus: event,
      };

    default:
      event = {
        bg: "primary.red.hover",
        color: "white",
      };

      return {
        bg: "primary.red",
        color: "white",
        _hover: event,
        _focus: event,
        _disabled: {
          bg: "#AAAAAA",
        },
        _loading: {
          opacity: 1,
          bg: "primary.red.hover",
          _hover: { bg: "primary.red.hover" },
        },
      };
  }
};

export const defaultVariant = defineStyle((props) => {
  return {
    ...generateThemeFromColorScheme(props),
    _disabled: {
      bg: mode("secondary.gray", "secondary.gray")(props),
      color: "white",
    },
  };
});

export const outlineVariant = defineStyle((props) => {
  return {
    bg: "black",
    border: "1px solid",
    borderRadius: "2px",
    borderColor: "primary.red",
    color: mode("primary.red", "primary.red")(props),
    _hover: {
      borderColor: "primary.red.hover",
      color: "primary.red.hover",
      bg: "transparent",
    },
    _selected: {
      borderColor: "primary.red.hover",
      color: "primary.red.hover",
    },
    _active: {
      borderColor: "primary.red.hover",
      color: "primary.red.hover",
    },
    _focus: {
      borderColor: "primary.red.hover",
      color: "primary.red.hover",
    },
  };
});

export const noneStyleVariant = defineStyle(() => {
  return {
    border: "none",
    bg: "transparent",
    color: "primary.red",
    _hover: {
      color: "primary.red.hover",
    },
  };
});

const variants = {
  solid: defaultVariant,
  outline: outlineVariant,
  none: noneStyleVariant,
};

const baseStyle = defineStyle({
  px: "12px",
  py: "12px",
  overflow: "hidden",
  borderRadius: "2px",
  transition: "all 0.3s ease-in-out",
  fontWeight: 500,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  _focusVisible: {
    boxShadow: { base: "none", lg: "outline" },
  },
  // _before: {
  //   content: "''",
  //   position: "absolute",
  //   top: "20px",
  //   left: 0,
  //   right: 0,
  //   height: "300px",
  //   width: "100%",
  //   bg: "white",
  //   borderRadius: "2px",
  //   opacity: 0,
  //   transition: "all 0.3s ease-in-out",
  // },
});

const sizes = {
  sm: {
    px: "12px",
    py: "12px",
    fontSize: "14px",
  },
  md: {
    px: "16px",
    py: "12px",
    fontSize: "20px",
  },
  lg: {
    px: "16px",
    py: "16px",
    fontSize: "18px",
  },
};

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: "md",
    variant: "solid",
    colorScheme: "blue",
  },
});

export default Button;
