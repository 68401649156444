import { SolanaSignerService } from "@/lib/services/solana.signer.service";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSolanaWeb3 } from "./SolanaWeb3Provider";
import { Transaction, TransactionInstruction } from "@solana/web3.js";
// import { useWallet } from "@solana/wallet-adapter-react";
import { useWallet } from "@jup-ag/wallet-adapter";

export type SolanaSignerContextType = {
  signerService: {
    solana: SolanaSignerService;
  };

  signAndSendTransaction(
    instructions: TransactionInstruction[],
  ): Promise<string>;
};

export const SolanaSignerContext = createContext<SolanaSignerContextType>(null);
export const useSolanaSigner = () => {
  const context = useContext(SolanaSignerContext);
  if (!context) {
    throw new Error(
      "useSolanaSigner must be used within a SolanaSignerContext",
    );
  }

  return context;
};

export const SolanaSignerProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { connection } = useSolanaWeb3();
  const { publicKey, signTransaction } = useWallet();
  const [solana, setSolana] = useState<SolanaSignerService>();

  const signAndSendTransaction = useCallback(
    async (instructions: TransactionInstruction[]): Promise<string> => {
      const transaction = new Transaction();

      transaction.add(...instructions);

      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction(transaction);
      const rawTransaction = signedTransaction.serialize();
      const signature = await connection.sendRawTransaction(rawTransaction);

      console.log(`Transaction sent: ${signature}`);
      console.log(`Transaction signature: ${signature}`);

      const latestBlockHash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: signature,
      });
      console.log(`Transaction confirmed: ${signature}`);
      return signature;
    },
    [publicKey, connection],
  );

  useEffect(() => {
    setSolana(new SolanaSignerService(connection));
  }, [connection]);

  return (
    <SolanaSignerContext.Provider
      value={{ signAndSendTransaction, signerService: { solana } }}
    >
      {children}
    </SolanaSignerContext.Provider>
  );
};
