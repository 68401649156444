import { useCallback, useEffect, useRef, useState } from "react";
import { useSolanaNetwork } from "./useSolanaNetwork";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { useSupabase } from "./useSupabase";
import { useDebounce } from "@uidotdev/usehooks";

import { useUnifiedWalletContext, useWallet } from "@jup-ag/wallet-adapter";

const useSolana = () => {
  const { publicKey, disconnect: disconnectSolana, connected } = useWallet();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useSolanaNetwork();
  const { setShowModal } = useUnifiedWalletContext();
  const fetchBalance = useCallback(async () => {
    if (!publicKey) return;

    try {
      setIsLoading(true);
      const lamports = await connection.getBalance(publicKey);
      const solBalance = lamports / 10 ** 9;
      setBalance(solBalance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
      setBalance(0);
    } finally {
      setIsLoading(false);
    }
  }, [connection, publicKey]);

  useEffect(() => {
    fetchBalance();
  }, [publicKey]);

  return {
    connected,
    balance,
    publicKey,
    isLoading,
    disconnectSolana,
    connectSolana: async () => {
      try {
        setShowModal(true);
      } catch (err) {
        console.error("Failed to connect to wallet:", err);
      }
    },
    fetchBalance,
  };
};

export const useWalletConnect = () => {
  const {
    publicKey: _publicKey,
    balance: solBalance,
    disconnectSolana,
    connectSolana,
    connected,
  } = useSolana();

  const publicKey = useDebounce(_publicKey, 2000);

  const { auth, logout, loginSolana } = useSupabase();

  const balance = AppNumber.from(solBalance);
  const previousPublicKey = useRef(null);

  const isAppAuth = publicKey && auth;

  const handleDisconnectSolana = useCallback(async () => {
    await disconnectSolana();
    logout();
    previousPublicKey.current = null;
  }, [logout, disconnectSolana]);

  const handleLogin = useCallback(async () => {
    if (!publicKey) return;
    try {
      await loginSolana();
    } catch (error) {
      await handleDisconnectSolana();
    }
  }, [publicKey, loginSolana]);

  return {
    balance,
    connected,
    publicKey,
    isAppAuth,
    solBalance: solBalance,
    address: publicKey?.toBase58()?.toString(),
    handleLogin,
    connectSolana,
    disconnectSolana: handleDisconnectSolana,
  };
};
