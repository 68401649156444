import { Button, ButtonProps, Center, Image } from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, memo } from "react";
import { useWalletConnect } from "@/hooks/useWalletConnect";
import { useSupabase } from "@/hooks/useSupabase";

const HeaderHamburgerButton = (
  { onClick, isOpen, ...props }: Partial<ButtonProps> & { isOpen: boolean },
  ref?: ForwardedRef<HTMLButtonElement>,
) => {
  const { address } = useWalletConnect();
  const { auth } = useSupabase();

  return (
    <Button
      order={4}
      as={Center}
      borderRadius="1px"
      variant="unstyled"
      onClick={onClick}
      display="flex"
      width={address && auth?.user?.id ? "24px" : "40px"}
      height={address && auth?.user?.id ? "24px" : "40px"}
      minWidth="24px"
      minHeight="24px"
      cursor="pointer"
      {...props}
      ref={ref}
      border="1px solid"
      borderColor={isOpen ? "primary.red.hover" : "stroke"}
      _hover={{ borderColor: "primary.red.hover" }}
    >
      <Image
        src={`/icons/menu-${isOpen ? "close" : "open"}.svg`}
        alt="Close"
        width="16px"
        height="16px"
        cursor="pointer"
      />
    </Button>
  );
};

export default memo(forwardRef(HeaderHamburgerButton));
